import $ from "jquery";
import app from "./src/App";
import "./components/StickyHeader";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

$(".site-header__menu-toggle").click(() => {
  app.mainMenu.toggle();
});

$(".c-more").hover(
  function () {
    $(this).removeClass("c-more--hover-out");
    $(this).addClass("c-more--hover-in");
  },
  function () {
    $(this).removeClass("c-more--hover-in");
    $(this).addClass("c-more--hover-out");
  },
);

$(".footer__menu .menu-item").hover(
  function () {
    $(this).removeClass("hover-out");
    $(this).addClass("hover-in");
  },
  function () {
    $(this).removeClass("hover-in");
    $(this).addClass("hover-out");
  },
);

$(".animation-reveal").each(function () {
  gsap.from(this, {
    duration: 1,
    y: 100,
    opacity: 0,
    scrollTrigger: {
      trigger: this,
      start: "top 90%",
      once: true,
    },
  });
});

$(".cookie-policy-toggle").click((e) => {
  e.preventDefault();
  revisitCkyConsent();
});
