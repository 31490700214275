import $ from "jquery";

/**
 *
 * @class MainMenu
 */
class MainMenu {
  #isOpen = false;

  constructor() {
    const $offCanvas = $(".offcanvas-menu");

    $("#menu-main-menu .menu-item").hover(
      function () {
        $(this).removeClass("hover-out");
        $(this).addClass("hover-in");
      },
      function () {
        $(this).removeClass("hover-in");
        $(this).addClass("hover-out");
      },
    );

    $offCanvas.find(".sub-menu").prepend(`
      <div class='offcanvas-menu__header d-flex align-items-center'>
        <div class='offcanvas-menu__back type--h4'>
          <div class='offcanvas-menu__back-icon'>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M5.40425 12.4529L15.6745 22.6038C15.8001 22.729 15.9496 22.8284 16.1143 22.8962C16.279 22.964 16.4556 22.9989 16.634 22.9989C16.8124 22.9989 16.989 22.964 17.1537 22.8962C17.3183 22.8284 17.4678 22.729 17.5934 22.6038C17.8509 22.3485 17.9955 22.0027 17.9955 21.6422C17.9955 21.2817 17.8509 20.9359 17.5934 20.6805L8.29614 11.4912L17.5934 2.30187C17.7201 2.1777 17.8206 2.02998 17.8892 1.86722C17.9578 1.70445 17.9932 1.52988 17.9932 1.35355C17.9932 1.17723 17.9578 1.00265 17.8892 0.839892C17.8206 0.67713 17.7201 0.529405 17.5934 0.405238C17.4728 0.276916 17.3263 0.174972 17.1634 0.105963C17.0004 0.0369533 16.8247 0.00240567 16.6475 0.00454117C16.2823 0.00542547 15.9323 0.149541 15.6745 0.405238L5.40425 10.6898C5.15205 10.938 5.00678 11.2731 4.99884 11.6247C5.02815 11.9402 5.17219 12.2344 5.40425 12.4529Z" fill="currentColor"/>
            </svg>
          </div>
          <div class='offcanvas-menu__back-text'>${$offCanvas.data("back-text")}</div>
        </div>
        <div class="site-header__nav d-flex align-items-center">
          <div class="site-header__menu-toggle">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M27.0607 0.93934C27.6464 1.52513 27.6464 2.47487 27.0607 3.06066L16.1213 14L27.0607 24.9393C27.6464 25.5251 27.6464 26.4749 27.0607 27.0607C26.4749 27.6464 25.5251 27.6464 24.9393 27.0607L14 16.1213L3.06066 27.0607C2.47487 27.6464 1.52513 27.6464 0.93934 27.0607C0.353553 26.4749 0.353553 25.5251 0.93934 24.9393L11.8787 14L0.93934 3.06066C0.353553 2.47488 0.353553 1.52513 0.93934 0.939344C1.52513 0.353558 2.47487 0.353558 3.06066 0.939344L14 11.8787L24.9393 0.93934C25.5251 0.353553 26.4749 0.353553 27.0607 0.93934Z" fill="currentColor"/>
            </svg>
          </div>
        </div>
       </div>`);

    $offCanvas.find(".offcanvas-menu__back").click(function () {
      $(this).closest(".sub-menu").removeClass("is-open");
    });

    $offCanvas.find(".menu-item-has-children > a").click(function (e) {
      e.preventDefault();
      $(this).next(".sub-menu").addClass("is-open");
    });

    const mediaQuery = window.matchMedia("(min-width: 992px)");
    mediaQuery.addEventListener("change", (e) => {
      if (e.matches) {
        this.close();
      }
    });
  }

  /**
   * Opens the website main menu
   */
  open() {
    this.#isOpen = true;
    $("body").addClass("menu-open");
  }

  /**
   * Closes the website main menu
   */
  close() {
    this.#isOpen = false;
    $(".offcanvas-menu__back").click();
    $("body").removeClass("menu-open");
  }

  /**
   * Toggles the website main menu
   */
  toggle() {
    if (this.#isOpen) {
      this.close();
    } else {
      this.open();
    }
  }
}

export default MainMenu;
