import $ from "jquery";
import app from "../src/App";
import { debounce } from "lodash";

let didScroll;
let lastScrollTop = 0;
const delta = 5;
const $colorChangeTrigger = $(".hero").length ? $(".hero") : $(".site-header");

$(window).scroll(() => {
  didScroll = true;
});

setInterval(function () {
  if (didScroll) {
    hasScrolled();
    didScroll = false;
  }
}, 250);

function hasScrolled() {
  const st = $(window).scrollTop();

  // Make sure they scroll more than delta
  if (Math.abs(lastScrollTop - st) <= delta) return;

  const triggerOffset = $colorChangeTrigger.offset().top + $colorChangeTrigger.outerHeight();

  // If they scrolled down and are past the navbar, add class .nav-up.
  // This is necessary, so you never see what is "behind" the navbar.
  if (st > lastScrollTop && st > triggerOffset) {
    // Scroll Down
    $("body").removeClass("header-down").addClass("header-up");
  } else {
    // Scroll Up
    if (st + $(window).height() < $(document).height()) {
      $("body").removeClass("header-up").addClass("header-down");
    }
  }

  lastScrollTop = st;
}
let intersectionObserver;
const setUpIntersectionObserver = () => {
  intersectionObserver = new IntersectionObserver(
    ([entry]) => {
      if (!entry.isIntersecting) {
        $(".site-header").addClass("site-header--scrolled");
      } else {
        $(".site-header").removeClass("site-header--scrolled");
      }
    },
    {
      rootMargin: `-${app.getCustomProperty("header-height")} 0px 0px 0px`,
    },
  );
  intersectionObserver.observe($colorChangeTrigger[0]);
};
window.addEventListener("resize", debounce(setUpIntersectionObserver, 250));
setUpIntersectionObserver();
